import './App.css';
import React, {Suspense}  from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Row ,Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import {   Routes, Route } from 'react-router-dom';
import { BrowserRouter as Router} from 'react-router-dom';




const Navs = React.lazy(() => import('./components/Navs')) ;
const Side = React.lazy(() => import('./components/Side')) ;
const Footer = React.lazy(() => import('./components/Footer')) ;

const Sidebar = React.lazy(() => import('./components/Sidebar')) ;
const Home = React.lazy(() => import('./components/Home')) ;


const Channels = React.lazy(() =>  import('./components/side/Channels')) ;
const Categories = React.lazy(() =>   import('./components/side/Categories')) ;
const Cat = React.lazy(() =>   import('./components/side/Cat')) ;
const Earn = React.lazy(() =>   import('./components/side/Earn')) ;
const Liked = React.lazy(() =>   import('./components/side/Liked')) ;
const Messages = React.lazy(() =>   import('./components/side/Messages')) ;
const MyVids = React.lazy(() =>   import('./components/side/MyVids')) ;

const Account = React.lazy(() =>   import('./components/side/Acc')) ;
const Update = React.lazy(() =>   import('./components/side/Update')) ;
//const Subscibe = React.lazy(() =>   import('./components/side/Subscribe')) ;
const HomePage = React.lazy(() =>   import('./components/side/shop/HomePage')) ;
const Details = React.lazy(() =>   import('./components/side/shop/Details')) ;
const Cart = React.lazy(() =>   import('./components/side/shop/Cart')) ;
const AddProducts = React.lazy(() =>   import('./components/side/shop/AddProducts')) ;
//const Payments = React.lazy(() =>   import('./components/side/shop/Payments')) ;

const Contact = React.lazy(() =>   import('./components/footer/Contact')) ;
const Report = React.lazy(() =>   import('./components/footer/Report')) ;
const Ask = React.lazy(() =>   import('./components/footer/Ask')) ;
const Terms = React.lazy(() =>   import('./components/footer/Terms')) ;
const Privacy = React.lazy(() =>   import('./components/footer/Privacy')) ;

const Search = React.lazy(() =>   import('./components/nav/Search')) ;
const Upload = React.lazy(() =>   import('./components/nav/Upload')) ;
const SignUp = React.lazy(() =>   import('./components/nav/SignUp')) ;
const Login = React.lazy(() =>   import('./components/nav/Login')) ;
const Verify = React.lazy(() =>   import('./components/nav/Ver')) ;
const Forgot = React.lazy(() =>   import('./components/nav/Forgot')) ;

const Video = React.lazy(() =>   import('./components/details/Video')) ;

const None = React.lazy(() =>   import('./components/home/all/None')) ;
const Most = React.lazy(() =>   import('./components/home/all/Most')) ;
const Picked = React.lazy(() =>   import('./components/home/all/Picked')) ;
const Popular = React.lazy(() =>   import('./components/home/all/Popular')) ;
const Random = React.lazy(() =>   import('./components/home/all/Random')) ;
const Recent = React.lazy(() =>   import('./components/home/all/Recent')) ;
const Watched = React.lazy(() =>   import('./components/home/all/Watched')) ;
const Viewall = React.lazy(() =>   import('./components/home/all/Viewall')) ;


function App() {
  const [showHideContent, setShowHideContent] = React.useState("none");
  const toggleVisibility = () =>
    setShowHideContent((value) => (value === "none" ? "block" : "none"));

  return (
    <Suspense fallback={
    <div style={{backgroundColor:"#121212",height:1000,width:"100%",overflow:"hidden"}}>
    <div  style={{ height:300,width:"100%"}}/>
    <i className="icon-spin5"  style={{ marginRight:0,marginLeft:"45%",marginTop:500,fontSize: "30px",color:"#E2DFD2"}}  />
    <br/> 
                    <h6 style={{textAlign:"center",marginLeft:-50,color:"white",marginTop:25,fontSize: "10px",textTransform: "uppercase",letterSpacing: "2px",fontWeight: 700,lineHeight: 1.1,width:"100%"}} className='main-p'>Blackteens @ 2022</h6>
                    
    
    
    </div>
    }>
           
    <Router  >
    <div className="App">
      <Navs onClick={toggleVisibility} showHideContent={showHideContent} />
      <Row style={{position:"relative",marginLeft:0}}  className='App-row'>
      <div onClick={toggleVisibility} style={{display: showHideContent,flex:1,position:"fixed",zIndex:9,left:0,height:"100%",width:"100%",backgroundColor:"rgba(0,0,0,0.8)"}}>
      <div style={{display: showHideContent,flex:1,position:"fixed",zIndex:9,right:0,height:"100%",width:"50%",backgroundColor:"#121212"}}>
              
              </div>
              <div style={{display: showHideContent,flex:1,position:"fixed",zIndex:9,right:0,height:"100%",width:"50%",backgroundColor:"#121212"}}>
              <Sidebar/>
              </div>
        </div>
        <Col lg={1} xl={2} className='App-header' style={{backgroundColor:"#121212",paddingTop:60,position:"fixed"}}   >
        
          <Side />
        </Col>
        <Col lg={11} xl={10} style={{backgroundColor:"#121212",paddingTop:75,position:"absolute",right:0}} >
           
          <Routes>
            <Route path="/" element={<Home/>} />
            <Route path="search/:id" element={<Search/>} />
            <Route path="upload" element={<Upload/>} />
            <Route path="login" element={<Login/>} />
            <Route path="signup" element={<SignUp/>} />
            <Route path="verify" element={<Verify/>} />
            <Route path="forgot" element={<Forgot/>} />

            <Route path="video/:id" element={<Video/>} />


            <Route path="addproducts" element={<AddProducts/>} />
            <Route path="channels" element={<Channels/>} />
            <Route path="categories" element={<Categories/>} />
            <Route path="categories/:id" element={<Cat/>} />
            <Route path="earn" element={<Earn/>} />
            <Route path="shop" element={<HomePage/>} />
            <Route path="liked" element={<Liked/>} />
            <Route path="messages" element={<Messages/>} />
            <Route path="myvids" element={<MyVids/>} />
            <Route path="profile" element={<Account/>} />
            <Route path="update" element={<Update/>} />
            <Route path="details/:id" element={<Details/>} />
            <Route path="cart/:id" element={<Cart/>} />
            <Route path="contact" element={<Contact/>} />
            <Route path="report" element={<Report/>} />
            <Route path="terms" element={<Terms/>} />
            <Route path="ask" element={<Ask/>} />
            <Route path="privacy" element={<Privacy/>} />






            <Route path="none-n" element={<None/>} />
            <Route path="mostviewed" element={<Most/>} />
            <Route path="handpicked" element={<Picked/>} />
            <Route path="popular" element={<Popular/>} />
            <Route path="random" element={<Random/>} />
            <Route path="recent" element={<Recent/>} />
            <Route path="watched" element={<Watched/>} />
            <Route path="ViewAll" element={<Viewall/>} />

          </Routes>
          
          
          <Footer/>
        </Col>
        
        
        
      </Row>
      
    </div>
    </Router>
    </Suspense>
  );
}

export default App;
